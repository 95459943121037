import { Container, makeStyles, Typography } from '@material-ui/core'
import { graphql } from 'gatsby'
import { groupBy } from 'lodash'
import React from 'react'
import { SeasonScheduleQuery } from '../../graphql-types'
import ScheduleItem from '../components/Schedule/ScheduleItem'
import useSEO from '../components/Shared/useSEO'
import format from '../util/format'

const useStyles = makeStyles(theme => ({
    heading: {
        color: theme.palette.grey[600],
        fontWeight: 700,
        textTransform: 'uppercase',
        fontStyle: 'italic',
        letterSpacing: -5,
        // fontSize: 200,
        // [theme.breakpoints.down("md")]: {
        //   fontSize: 150,
        // },
        // [theme.breakpoints.down("xs")]: {
        //   fontSize: 90,
        // },
    },
}))

interface SeasonScheduleProps {
    data: SeasonScheduleQuery
}

export default ({ data }: SeasonScheduleProps) => {
    useSEO({ title: `Spielplan ${data.sanitySeason.title}` })
    const classes = useStyles()

    const groupedByMonth = groupBy(data.allSanitySchedule.nodes, s => new Date(s.date).getMonth())

    return (
        <Container maxWidth="md">
            <Typography variant="h1" className={classes.heading}>
                Spielplan
            </Typography>
            {Object.entries(groupedByMonth).map(([month, scheduleForMonth]) => (
                <div>
                    <Typography variant="h1">
                        {format(new Date(2000, Number(month)), 'MMMM')}
                    </Typography>
                    {scheduleForMonth.map(si => (
                        <ScheduleItem item={si} />
                    ))}
                </div>
            ))}
        </Container>
    )
}

export const query = graphql`
    query SeasonSchedule($seasonId: String!, $startDate: Date!, $endDate: Date!) {
        sanitySeason(id: { eq: $seasonId }) {
            id
            title
        }
        allSanitySchedule(
            filter: { date: { gte: $startDate, lte: $endDate } }
            sort: { fields: date, order: ASC }
        ) {
            nodes {
                id
                homeTeam
                homePoints
                awayTeam
                awayPoints
                date
                isAwayGame
                place
            }
        }
    }
`
