import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import format from '../../util/format'

const ScheduleItem = ({ item }) => {
    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Typography>{format(new Date(item.date), 'EEEEEE, dd.MM.yyyy, HH:mm')}</Typography>
                <Typography>{item.isAwayGame ? 'Auswärtsspiel' : 'Heimspiel'} </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Typography>{item.homeTeam}</Typography>
                <Typography>
                    {renderPoints(item.homePoints)}:{renderPoints(item.awayPoints)}
                </Typography>
                <Typography>{item.awayTeam}</Typography>
            </Box>
            <Divider />
        </>
    )
}

const renderPoints = (points?: number): string => (points ? points.toString() : '-')

export default ScheduleItem
